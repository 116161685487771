// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-gold-coast-js": () => import("./../../../src/pages/aboutGoldCoast.js" /* webpackChunkName: "component---src-pages-about-gold-coast-js" */),
  "component---src-pages-about-tofino-js": () => import("./../../../src/pages/aboutTofino.js" /* webpackChunkName: "component---src-pages-about-tofino-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cottages-js": () => import("./../../../src/pages/cottages.js" /* webpackChunkName: "component---src-pages-cottages-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-getting-here-js": () => import("./../../../src/pages/gettingHere.js" /* webpackChunkName: "component---src-pages-getting-here-js" */),
  "component---src-pages-haidaway-cottage-js": () => import("./../../../src/pages/haidawayCottage.js" /* webpackChunkName: "component---src-pages-haidaway-cottage-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-location-js": () => import("./../../../src/pages/location.js" /* webpackChunkName: "component---src-pages-location-js" */),
  "component---src-pages-rates-js": () => import("./../../../src/pages/rates.js" /* webpackChunkName: "component---src-pages-rates-js" */),
  "component---src-pages-reservations-js": () => import("./../../../src/pages/reservations.js" /* webpackChunkName: "component---src-pages-reservations-js" */),
  "component---src-pages-shipwreck-cabin-js": () => import("./../../../src/pages/shipwreckCabin.js" /* webpackChunkName: "component---src-pages-shipwreck-cabin-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */)
}

